import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const listRecipient = parameters =>
  api.get(`/admin/organization/recipient${objectToURLParams(parameters)}`);

export const registerRecipient = (body, organizationId) =>
  api.post(`/admin/organization/recipient?organizationId=${organizationId}`, {
    ...body,
  });

export const editRecipient = ({ id, body }, organizationId) =>
  api.put(
    `/admin/organization/recipient/${id}?organizationId=${organizationId}`,
    body
  );

export const deleteRecipient = (id, organizationId) =>
  api.delete(
    `/admin/organization/recipient/${id}?organizationId=${organizationId}`
  );

export const undeleteRecipient = (id, organizationId) =>
  api.put(`/user/undelete/${id}?organizationId=${organizationId}`);

export const turnDriver = (id, organizationId) =>
  api.put(`/user/turnDriver/${id}?organizationId=${organizationId}`);

export const passwordRecovery = ({ id, body }, organizationId) =>
  api.put(
    `/admin/organization/passrecovery/${id}?organizationId=${organizationId}`,
    body
  );

export const getRecipientStatement = (id, parameters) =>
  api.get(`/v2/user/statement${objectToURLParams(parameters)}&_user=${id}`);
