import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import { getCurrentOrganization } from 'redux/organizations/selectors';
import { BILLING_STATUS_TYPES } from 'constants/defaultValues';
import {
  listOrganizationInvoicesSuccess,
  listOrganizationInvoicesError,
  organizationInvoicesTransactionsSuccess,
  organizationInvoicesTransactionsError,
  ORGANIZATION_INVOICES_LIST_REQUEST,
  ORGANIZATION_INVOICES_TRANSACTIONS_REQUEST,
} from './actions';

function* listOrganizationInvoices(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);

  if (!currentOrganization || !currentOrganization.id) {
    const message = 'Organização atual não encontrada';
    yield put(listOrganizationInvoicesError(message));
    return;
  }

  const response = yield call(
    api.list,
    APIParams({ ...payload.params, organizationId: currentOrganization.id })
  );

  if (response.ok) {
    let list = response.data.docs;

    list = list.value.data.filter(
      item => item.status !== BILLING_STATUS_TYPES[1].value
    );

    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listOrganizationInvoicesSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(listOrganizationInvoicesError(message));
  }
}

function* getOrganizationInvoicesTransactions(api, { payload }) {
  const response = yield call(api.getTransactions, APIParams(payload.params));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(organizationInvoicesTransactionsSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(organizationInvoicesTransactionsError(message));
  }
}

export function* watchListOrganizationInvoices(api) {
  yield takeEvery(
    ORGANIZATION_INVOICES_LIST_REQUEST,
    listOrganizationInvoices,
    api
  );
}

export function* watchOrganizationInvoicesTransactions(api) {
  yield takeEvery(
    ORGANIZATION_INVOICES_TRANSACTIONS_REQUEST,
    getOrganizationInvoicesTransactions,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListOrganizationInvoices, api),
    fork(watchOrganizationInvoicesTransactions, api),
  ]);
}
