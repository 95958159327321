import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const listVehicle = parameters =>
  api.get(`/v2/admin/organization/vehicle${objectToURLParams(parameters)}`);

export const registerVehicle = (body, organizationId) =>
  api.post(`/admin/organization/vehicle?organizationId=${organizationId}`, {
    ...body,
  });

export const editVehicle = ({ id, body }, organizationId) =>
  api.put(
    `/admin/organization/vehicle/${id}?organizationId=${organizationId}`,
    body
  );

export const deleteVehicle = (id, organizationId) =>
  api.delete(
    `/admin/organization/vehicle/${id}?organizationId=${organizationId}`
  );

export const undeleteVehicle = (id, organizationId) =>
  api.put(`/vehicle/undelete/${id}?organizationId=${organizationId}`);

export const getDataByLicensePlate = licensePlate =>
  api.get(`/vehicle/plate/${licensePlate}`);

export const registerVehicles = (body, organizationId) =>
  api.post(
    `/v2/admin/organization/vehicle?organizationId=${organizationId}`,
    body
  );

export const getVehicleStatement = (id, parameters) =>
  api.get(`/v2/user/statement${objectToURLParams(parameters)}&_vehicle=${id}`);
