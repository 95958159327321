import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const listDriver = parameters =>
  api.get(`/admin/organization/driver${objectToURLParams(parameters)}`);

export const registerDriver = (body, organizationId) =>
  api.post(`/admin/organization/driver?organizationId=${organizationId}`, {
    ...body,
  });

export const editDriver = ({ id, body, organizationId }) =>
  api.put(
    `/admin/organization/driver/${id}?organizationId=${organizationId}`,
    body
  );

export const deleteDriver = (id, organizationId) =>
  api.delete(
    `/admin/organization/driver/${id}?organizationId=${organizationId}`
  );

export const undeleteDriver = (id, organizationId) =>
  api.put(`/user/undelete/${id}?organizationId=${organizationId}`);

export const turnRecipient = (id, organizationId) =>
  api.put(`/user/turnRecipient/${id}?organizationId=${organizationId}`);

export const passwordRecovery = ({ id, body }, organizationId) =>
  api.put(
    `/admin/organization/passrecovery/${id}?organizationId=${organizationId}`,
    body
  );

export const registerDrivers = (body, organizationId) =>
  api.post(
    `/v2/admin/organization/driver?organizationId=${organizationId}`,
    body
  );

export const getDriverStatement = (id, parameters) =>
  api.get(`/v2/user/statement${objectToURLParams(parameters)}&_user=${id}`);
